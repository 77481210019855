import React from 'react';

const Footer = ({ customer, footer }) => {

    const cacheBustedUrl = `${customer?.logoImage}?v=${new Date().getTime()}`;

    return (
        <footer>
            <div className="container">
                <div className="footer">
                    <div className="row">
                        <div className="col-xl-4 col-lg-4 col-md-4 footer-box">
                            <div className="footer-logo">
                                <img 
                                    className="img-logo"
                                    src={cacheBustedUrl} 
                                    alt="" />
                                <p className="footer-des font-header-footer-color">
                                    {customer.address}
                                </p>
                                <ul>
                                    <li className="font-header-footer-color">phone  - <a href="#" className="font-header-footer-color font-weight-bold">{customer.phone}</a></li>
                                    <li className="font-header-footer-color">email  - <a href="#" className="font-header-footer-color font-weight-bold"><span className="__cf_email__">{customer.email}</span></a></li>
                                </ul>

                            </div>
                        </div>

                        <div className="col-xl-4 col-lg-4 col-md-4 footer-box">
                            <div className="opening-hours">
                                <h2 className="font-header-footer-color font-weight-bold">{footer.openingHours}</h2>
                                <ul>
                                    <li className="font-header-footer-color">
                                        Segunda-feira:
                                        {customer.mondayIsOpen ? (
                                            <span>{customer.mondayDescription} horas</span>
                                        ) : (
                                            <span className="footer-close font-primary-color">Fechado</span>
                                        )}
                                    </li>
                                    <li className="font-header-footer-color">
                                        Terça-feira:
                                        {customer.tuesdayIsOpen ? (
                                            <span>{customer.tuesdayDescription} horas</span>
                                        ) : (
                                            <span className="footer-close font-primary-color">Fechado</span>
                                        )}
                                    </li>
                                    <li className="font-header-footer-color">
                                        Quarta-feira:
                                        {customer.wednesdayIsOpen ? (
                                            <span>{customer.wednesdayDescription} horas</span>
                                        ) : (
                                            <span className="footer-close font-primary-color">Fechado</span>
                                        )}
                                    </li>
                                    <li className="font-header-footer-color">
                                        Quinta-feira:
                                        {customer.thursdayIsOpen ? (
                                            <span>{customer.thursdayDescription} horas</span>
                                        ) : (
                                            <span className="footer-close font-primary-color">Fechado</span>
                                        )}
                                    </li>
                                    <li className="font-header-footer-color">
                                        Sexta-feira:
                                        {customer.fridayIsOpen ? (
                                            <span className="font-header-footer-color">{customer.fridayDescription} horas</span>
                                        ) : (
                                            <span className="footer-close font-primary-color">Fechado</span>
                                        )}
                                    </li>
                                    <li className="font-header-footer-color">
                                        Sábado:
                                        {customer.saturdayIsOpen ? (
                                            <span>{customer.saturdayDescription} horas</span>
                                        ) : (
                                            <span className="footer-close font-primary-color">Fechado</span>
                                        )}
                                    </li>
                                    <li className="font-header-footer-color">
                                        Domingo:
                                        {customer.sundayIsOpen ? (
                                            <span>{customer.sundayDescription} horas</span>
                                        ) : (
                                            <span className="footer-close font-primary-color">Fechado</span>
                                        )}
                                    </li>
                                    <li className="font-header-footer-color">
                                        Feriados:
                                        {customer.holidayIsOpen ? (
                                            <span>{customer.holidayDescription} horas</span>
                                        ) : (
                                            <span className="footer-close font-primary-color">Fechado</span>
                                        )}
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div className="col-xl-4 col-lg-4 col-md-4 footer-box">
                            <div className="useful-links">
                                <h2 className="font-header-footer-color font-weight-bold">Useful Links</h2>
                                <ul>
                                    {/* Solo muestra el enlace About si aboutEnabled es true */}
                                    {footer.aboutEnabled && (
                                        <li>
                                            <a href="about.html" className="font-header-footer-color">{footer.aboutText}</a>
                                        </li>
                                    )}
                                    {/* Solo muestra el enlace Contact si contactEnabled es true */}
                                    {footer.contactEnabled && (
                                        <li>
                                            <a href="contact.html" className="font-header-footer-color">{footer.contactText}</a>
                                        </li>
                                    )}
                                </ul>
                            </div>
                        </div>

                    </div>
                </div>
                <div className="copyright">
                    <div className="row">
                        <div className="col-xl-6 col-lg-6 col-md-6 copyright-box">
                            <p className="copy-text">©Copyright <a href="" className="font-header-footer-color font-weight-bold">{customer.displayName}.</a> {footer.legalText}</p>
                        </div>

                        <div className="col-xl-6 col-lg-6 col-md-6 copyright-box">
                            <ul>
                                {/* Muestra el icono de Facebook si está habilitado */}
                                {customer.facebook && (
                                    <li>
                                        <a href={customer.facebookLink} target="_blank" rel="noopener noreferrer">
                                            <i className="fa fa-facebook font-primary-color" aria-hidden="true"></i>
                                        </a>
                                    </li>
                                )}

                                {/* Muestra el icono de Twitter (X) si está habilitado */}
                                {customer.twitter && (
                                    <li>
                                        <a href={customer.twitterLink} target="_blank" rel="noopener noreferrer">
                                            <i className="fa fa-twitter font-primary-color" aria-hidden="true"></i>
                                        </a>
                                    </li>
                                )}

                                {/* Muestra el icono de LinkedIn si está habilitado */}
                                {customer.linkedin && (
                                    <li>
                                        <a href={customer.linkedinLink} target="_blank" rel="noopener noreferrer">
                                            <i className="fa fa-linkedin font-primary-color" aria-hidden="true"></i>
                                        </a>
                                    </li>
                                )}

                                {/* Muestra el icono de Instagram si está habilitado */}
                                {customer.instagram && (
                                    <li>
                                        <a href={customer.instagramLink} target="_blank" rel="noopener noreferrer">
                                            <i className="fa fa-instagram font-primary-color" aria-hidden="true"></i>
                                        </a>
                                    </li>
                                )}

                                {/* Muestra el icono de WhatsApp si está habilitado */}
                                {customer.whatsapp && (
                                    <li>
                                        <a
                                            href={`https://wa.me/${customer.whatsappLink}`}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            <i className="fa fa-whatsapp font-primary-color" aria-hidden="true"></i>
                                        </a>
                                    </li>
                                )}
                            </ul>
                        </div>

                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
