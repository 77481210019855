import React, { useEffect } from 'react';
import Header from './list/header/Header';
import Banner from './list/banner/Banner';
import Footer from './list/footer/Footer';
import Products from './list/products/Products';

function MenuList({ pageMenu }) {

    useEffect(() => {
        document.documentElement.style.setProperty('--primary-color', customer.primaryColor ? customer.primaryColor : '#fd9d3e');
        document.documentElement.style.setProperty('--font-primary-color', customer.fontPrimaryColor ? customer.fontPrimaryColor : '#fd9d3e');

        document.documentElement.style.setProperty('--header-footer-color', customer.headerFooterColor ? customer.headerFooterColor : '#000000');
        document.documentElement.style.setProperty('--font-header-footer-color', customer.fontHeaderFooterColor ? customer.fontHeaderFooterColor : '#ffffff');
     }, []);

    const customer = pageMenu?.customer;
    const header = pageMenu?.header;
    const banner = pageMenu?.banner;
    const menu = pageMenu?.menu;
    const footer = pageMenu?.footer;

    return (
        <div>
            <Header customer={customer} header={header} />
            <Banner banner={banner} />
            <Products menu={menu} />
            <Footer customer={customer} footer={footer} />
        </div>
    );
}

export default MenuList;
