import React, { useState, useEffect } from "react";
import ProductsService from '../../../../services/ProductsService';

const ProductDetail = ({ productId, closeProductDetail }) => {
    const [productDetails, setProductDetails] = useState({});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [quantity, setQuantity] = useState(1);

    useEffect(() => {
        const fetchProductDetailData = async () => {
            try {
                const data = await ProductsService.fetchProductDetails(productId);
                setProductDetails(data);
            } catch (error) {
                setError(error.message);
            } finally {
                setLoading(false);
            }
        };

        if (productId) {
            fetchProductDetailData();
        }
    }, [productId]);

    useEffect(() => {
        if (window.$ && productDetails.productImage) {
            const $ = window.$;
    
            // Esperar a que la imagen esté completamente cargada
            const img = new Image();
            img.onload = () => {
                $('#glasscase').glassCase({
                    'thumbsPosition': 'bottom',
                    'widthDisplayPerc': 100,
                    isDownloadEnabled: false,
                });
            };
            img.onerror = () => {
                console.error("Error loading the product image.");
            };
            img.src = productDetails.productImage;
        }
    }, [productDetails]);
    

    if (loading) return <div>Loading</div>;
    if (error) return <div>Error: {error}</div>;

    return (
        <section className="product-det pt-100">
            <div className="container">
                <div className="button-container button-margin-10">
                    <button onClick={closeProductDetail} className="btn btn-danger">
                        Close
                    </button>
                </div>
                <div className="row">
                    <div className="col-lg-5 col-md-6 col-12">
                        <div className="align-center mb-md-30">
                            <ul id="glasscase" className="gc-start">
                                <li>
                                    <img
                                        src={`${productDetails.productImage}?v=${new Date().getTime()}`}
                                        alt={productDetails.name || 'Product Image'}
                                    />
                                </li>
                  

                                {/*
                                <li><img src="images/p-2.png" alt="pizzon" /></li>
                                <li><img src="images/p-4.png" alt="pizzon" data-gc-caption="Your caption text" /></li>
                                <li><img src="images/p-5.png" alt="pizzon" /></li>
                                <li><img src="images/p-6.png" alt="pizzon" /></li>
                                */}
                            </ul>
                        </div>
                    </div>
                    <div className="col-xl-7 col-lg-7 col-md-7">
                        <div className="shop-detail">
                            <div className="shop-name">
                                <h3 className="title-shop">{productDetails.name}</h3>
                                <div className="price-shop">
                                    {/*<span className="filter-price filter-price-r">{(productDetails.price + 5)} {productDetails.currency}</span>*/}
                                    <span className="filter-price">{productDetails.price} {productDetails.currency}</span>
                                </div>
                                <p className="shop-desc">
                                    {productDetails.description}
                                </p>
                            </div>
                            {/*
                            <div className="crust-choose">
                                <label className="title-crust">Choose Your Crust</label>
                                <ul className="Choose">
                                    <li className="tab-link"><a href="javascript:void(0)">Classic Hand Tossed</a></li>
                                    <li className="tab-link"><a href="javascript:void(0)">Wheat Thin Crust</a></li>
                                    <li className="tab-link"><a href="javascript:void(0)">Classic Hand Tossed</a></li>
                                </ul>
                            </div>
                            <div className="crust-choose">
                                <label className="title-crust">Size of Crust</label>
                                <ul className="Size">
                                    <li className="tab-link"><a href="javascript:void(0)">Medium</a></li>
                                    <li className="tab-link"><a href="javascript:void(0)">Large</a></li>
                                    <li className="tab-link"><a href="javascript:void(0)">Regular</a></li>
                                </ul>
                            </div>
                            */}

                            <div className="crust-choose">
                                <label className="title-crust">Notes on allergies or any changes in ingredients</label>
                                <form class="main-form">
                                    <div class="row">
                                        <div class="col-12">
                                            <div class="form-group">
                                                <textarea className="form-control" placeholder="Message" rows="5"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div className="quantity-product">
                                <div className="qty-vertical-group">

                                    <label className="quantity">Qty:</label>
                                    <input
                                        type="number"
                                        className="quantity-input"
                                        value={quantity}
                                        min="1"
                                        max="100"
                                        onChange={(e) => {
                                            const newValue = Number(e.target.value);
                                            if (!isNaN(newValue) && newValue >= 1 && newValue <= 100) {
                                                setQuantity(newValue);
                                            }
                                        }}
                                    />
                                    <div className="btn-vertical-group">
                                        <a
                                            className="btn-primary-color btn-up-down"
                                            onClick={() => setQuantity(prev => Math.min(prev + 1, 100))}
                                        >
                                            ▲
                                        </a>
                                        <a
                                            className="btn-primary-color btn-up-down"
                                            onClick={() => setQuantity(prev => Math.max(prev - 1, 1))}
                                        >
                                            ▼
                                        </a>
                                    </div>
                                </div>
                                <a href="cart.html" className="add-cart btn-primary-color font-primary-color">
                                    <i className="fa fa-shopping-bag" aria-hidden="true"></i> Add to cart
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

const ProductDescriptionTab = () => {
    const [activeTab, setActiveTab] = useState("tab-1");

    const handleTabClick = (tabId) => {
        setActiveTab(tabId);
    };

    return (
        <section className="desc-tabbing pt-100">
            <div className="container">
                <div className="row">
                    <div className="col-xl-12 col-lg-12 col-md-12">
                        <div className="border-tab">
                            <ul className="panel-tab">
                                <li
                                    className={`tab-link ${activeTab === "tab-1" ? "current" : ""}`}
                                    onClick={() => handleTabClick("tab-1")}
                                >
                                    <a href="javascript:void(0)">Description</a>
                                </li>
                                {/*
                                <li
                                    className={`tab-link ${activeTab === "tab-2" ? "current" : ""}`}
                                    onClick={() => handleTabClick("tab-2")}
                                >
                                    <a href="javascript:void(0)">Reviews</a>
                                </li>
                                */}
                            </ul>
                            <div
                                className={`product-desc-tab ${activeTab === "tab-1" ? "current" : ""}`}
                                id="tab-1"
                            >
                                <p>Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Vestibulum tortor quam, feugiat vitae, ultricies eget, tempor sit amet, ante. Donec eu libero sit amet quam egestas semper. Aenean ultricies mi vitae est. Mauris placerat eleifend leo. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Vestibulum tortor quam, feugiat vitae, ultricies eget, tempor sit amet, ante. Donec eu libero sit amet quam egestas semper. Aenean ultricies mi vitae est. Mauris placerat eleifend leo. tempor sit amet, ante. Donec eu libero sit amet quam egestas semper. </p>
                                <p></p>

                            </div>

                            <div
                                className={`product-desc-tab ${activeTab === "tab-2" ? "current" : ""}`}
                                id="tab-2"
                            >
                                {/*
                                <div className="comment-part">
                                    <h3>03 COMMENTS</h3>
                                    <ul>
                                        <li>
                                            <div class="comment-user">
                                                <img src="images/comment-img1.jpg" alt="comment-img" />
                                            </div>
                                            <div class="comment-detail">
                                                <span class="commenter">
                                                    <span>John Doe</span> (05 Jan 2020)
                                                </span>
                                                <p>Lorem ipsum dolor sit amet adipiscing elit labore dolore that sed do eiusmod tempor labore dolore that magna aliqua. Ut enim ad minim veniam, exercitation.</p>
                                                <a href="#" class="reply-btn btn btn-color small">Reply</a>
                                            </div>
                                            <div class="clearfix"></div>
                                        </li>
                                        <li>
                                            <ul>
                                                <li>
                                                    <div class="comment-user">
                                                        <img src="images/comment-img2.jpg" alt="comment-img" />
                                                    </div>
                                                    <div class="comment-detail">
                                                        <span class="commenter">
                                                            <span>John Doe</span> (12 Jan 2020)
                                                        </span>
                                                        <p>Lorem ipsum dolor sit amet adipiscing elit labore dolore that sed do eiusmod tempor labore dolore that magna aliqua. Ut enim ad minim veniam, exercitation.</p>
                                                        <a href="#" class="reply-btn btn btn-color small">Reply</a>
                                                    </div>
                                                    <div class="clearfix"></div>
                                                </li>
                                                <li>
                                                    <div class="comment-user">
                                                        <img src="images/comment-img3.jpg" alt="comment-img" />
                                                    </div>
                                                    <div class="comment-detail">
                                                        <span class="commenter">
                                                            <span>John Doe</span> (15 Jan 2020)
                                                        </span>
                                                        <p>Lorem ipsum dolor sit amet adipiscing elit labore dolore that sed do eiusmod tempor labore dolore that magna aliqua. Ut enim ad minim veniam, exercitation.</p>
                                                        <a href="#" class="reply-btn btn btn-color small">Reply</a>
                                                    </div>
                                                    <div class="clearfix"></div>
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                </div>
                                <div class="leave-comment-part pt-100">
                                    <h3 class="reviews-head mb-30">Leave A Comment</h3>
                                    <form class="main-form">
                                        <div class="row">
                                            <div class="col-md-4">
                                                <div class="form-group">
                                                    <input type="text" class="form-control" placeholder="Name" required="" />
                                                </div>
                                            </div>
                                            <div class="col-md-4">
                                                <div class="form-group">
                                                    <input type="text" class="form-control" placeholder="Subject" required="" />
                                                </div>
                                            </div>
                                            <div class="col-md-4">
                                                <div class="form-group">
                                                    <input type="text" class="form-control" placeholder="Email" required="" />
                                                </div>
                                            </div>
                                            <div class="col-12">
                                                <div class="form-group">
                                                    <textarea class="form-control" placeholder="Message" rows="8"></textarea>
                                                </div>
                                            </div>
                                            <div class="col-12">
                                                <button type="submit" class="btn post-comm">Post Comment</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>*/}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

//export default ProductDetail;

export default function ProductPage({ productId, closeProductDetail }) {
    return (
        <div>
            <ProductDetail productId={productId} closeProductDetail={closeProductDetail} />
            {/*<ProductDescriptionTab />*/}
        </div>
    );
}
