import React, { useEffect, useState } from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom'; // Usamos useLocation

import Menu from './components/menu/Menu';
import ProductsService from './services/ProductsService';

const MenuListPage = () => {
  const [products, setProducts] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search); // Obtener los parámetros de la URL

  const code = queryParams.get('code');  // Obtener el valor de 'code'
  const table = queryParams.get('table');  // Obtener el valor de 'table'

  useEffect(() => {
    const fetchProductsData = async () => {
      try {
        const data = await ProductsService.fetchMenu(code, table); 
        setProducts(data);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    if (code && table) {
      fetchProductsData();  
    }
  }, [code, table]); 

  if (loading) return <div>Welcome to Smartmenu</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div>
      <Menu pageMenu={products} />
    </div>
  );
};

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<MenuListPage />} />
      </Routes>
    </Router>
  );
}

export default App;
